




















































































































import { Component, Vue } from 'vue-property-decorator';
import { MasterDriverModule } from '@/store/index';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { DriverType } from './driver-filter.model';
import { ToastHelper } from '@/utils/toast.util';
import Loading from '@/shared/components/Loading.vue';
import DIVFilter from '@/shared/components/filters/DIVFilter.vue';

@Component({
  components: {
    Loading,
    DIVFilter
  }
})
export default class DriverFilters extends Vue {
  masterDriverModule = MasterDriverModule;
  loading = false;
  positionOptions = [
    { value: null, text: 'All' },
    { value: 'Company', text: 'Company' },
    { value: 'Owner', text: 'Owner' },
    { value: 'Carrier', text: 'Carrier' }
  ];
  statusOptions = [
    { value: null, text: 'All' },
    { value: true, text: 'Active' },
    { value: false, text: 'Inactive' }
  ];

  constructor() {
    super();
  }

  DIVFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: DriverType.DIV
  });

  PositionFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: DriverType.Position
  });

  StatusFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: true,
    field: DriverType.Status
  });

  NameFilter = new FilterInput({
    condition: FilterConditions.BeginsWith,
    search: null,
    field: DriverType.Name
  });

  created() {
    this.search();
  }

  async search() {
    this.loading = true;

    try {
      this.masterDriverModule.setFilters(
        TransformFiltersToJson([
          this.DIVFilter,
          this.PositionFilter,
          this.StatusFilter,
          this.NameFilter
        ])
      );
      await this.masterDriverModule.search();
    } catch (err) {
      ToastHelper.show('Driver search', err.message, 5000, 'danger');
    }

    this.loading = false;
  }

  resetFilters() {
    this.DIVFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: DriverType.DIV
    });

    this.PositionFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: DriverType.Position
    });

    this.StatusFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: true,
      field: DriverType.Status
    });

    this.NameFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: DriverType.Name
    });

    this.masterDriverModule.setFilters(
      TransformFiltersToJson([
        this.DIVFilter,
        this.PositionFilter,
        this.StatusFilter,
        this.NameFilter
      ])
    );
  }
}
