








































































































import { Component, Vue } from 'vue-property-decorator';
import { MasterDriverModule } from '@/store/index';
import { DriverService } from '@/shared/services/mater-data/driver.service';
import DriverFilters from './DriverFilters/DriverFilters.vue';
import DriverModal from './DriverModal/DriverModal.vue';
import ToggleButton from '@/shared/components/form/ToggleButton.vue';
import { DriverHeaders } from './models/DriverHeaders';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import WoBaseModal from '../../Main/components/WoBaseModal.vue';
import { JobsService } from '@/shared/services/jobs/jobs.service';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: {
    DriverFilters,
    DriverModal,
    ToggleButton,
    TmsTable,
    WoBaseModal
  }
})
export default class Driver extends Vue {
  driverId = null;
  masterDriverModule = MasterDriverModule;
  counter = 0;
  modalTitle: string;

  showModal = true;
  loading = false;
  disabled = false;
  headersList = DriverHeaders;
  woModalId = 'wo-base-modal';
  selectedWo = null;
  tabIndex = 0;

  driverRelatedOrders = null;

  constructor() {
    super();
  }

  get tableData() {
    return this.masterDriverModule.drivers;
  }

  get isLastPage(): boolean {
    return !MasterDriverModule.meta.lastKey;
  }

  created() {
    this.disabled = PermissionsService.can('MASTER', 'DRIVER', 'View');
  }

  async changeDriver(drive) {
    if (this.showModal) {
      this.loading = true;
      this.driverRelatedOrders = await JobsService.getJobsByDriverId(drive.id);
      this.$bvModal.show('driver-modal');
      this.driverId = drive.id;
      this.modalTitle = `${drive.position} driver detail`;
      this.loading = false;
    }
    this.showModal = true;
  }

  onAction({ data, key }: { key: string; data: any }) {
    switch (key) {
      case 'active':
        this.changeActive(data);
        break;
      default:
        this.changeDriver(data);
    }
  }

  onInputChange({ field, data }) {
    switch (field) {
      case 'active':
        this.changeActive(data);
        break;
      default:
        break;
    }
  }

  async changeActive(driver) {
    this.loading = true;

    this.driverRelatedOrders = await JobsService.getJobsByDriverId(driver.id);
    driver.relatedOrders = this.driverRelatedOrders;

    if (driver.relatedOrders && driver.relatedOrders.length) {
      driver.active = true;
      this.$bvModal.show('active-driver');
    } else {
      await DriverService.changeDriverActive(driver.id, {
        active: driver.active
      });
    }

    this.loading = false;
  }

  onRowClicked(row) {
    row.category = row.type;
    this.selectedWo = row;
    this.tabIndex = 0;
    this.$bvModal.show(this.woModalId);
  }
}
