import { CellContentType } from '@/shared/components/table/models';
import { DATE_API_FORMAT } from '@/utils/date.util';
import moment from 'moment';

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}

export const DriverHeaders = [
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'division',
    label: 'DIV'
  },
  {
    key: 'position',
    label: 'Position'
  },
  {
    key: 'tabletId',
    label: 'Tablet ID'
  },
  {
    key: 'firstName',
    label: 'Name'
  },
  {
    key: 'cellPhone',
    label: 'CELL #'
  },
  {
    key: 'email',
    label: 'Email'
  },

  {
    key: 'city',
    label: 'City'
  },
  {
    key: 'state',
    label: 'State'
  },
  {
    key: 'zip',
    label: 'ZIP'
  },
  {
    key: 'driverLicenseNumber',
    label: 'DL#'
  },
  {
    key: 'driverLicenseExpirationDate',
    label: 'DL EXP',
    formatter: getDay
  },
  {
    key: 'haz',
    label: 'Haz'
  },
  {
    key: 'twicExpirationDate',
    label: 'TWIC EXP',
    formatter: getDay
  },
  {
    key: 'medicalExpirationDate',
    label: 'Medical EXP',
    formatter: getDay
  },
  {
    key: 'drugTestDate',
    label: 'Drug Test',
    formatter: getDay
  },
  {
    key: 'mc',
    label: 'MC #'
  },
  {
    key: 'dot',
    label: 'DOT #'
  },
  {
    key: 'hireDate',
    label: 'Hire',
    formatter: getDay
  },
  {
    key: 'terminateDate',
    label: 'Terminate',
    formatter: getDay
  },
  {
    key: 'active',
    label: 'Active',
    content: { type: CellContentType.Switcher }
  }
];
