
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DriverService } from '@/shared/services/mater-data/driver.service';
import { convertDateFieldToAPIFormat } from '@/utils/date.util';
import { validationMixin } from 'vuelidate';
import { FileUploadService } from '@/shared/services/fileUpload.service';
import FileDownload from '@/shared/components/form/FileDownload.vue';
import ToggleButton from '@/shared/components/form/ToggleButton.vue';
import { ToastHelper } from '@/utils/toast.util';
import {
  compDriverData,
  otherDriverData,
  otherDriverValid,
  companyDriverValid,
  onlyCompDriverData,
  onlyCompFilesDriverData,
  onlyOtherDriverData,
  onlyOtherFilesDriverData
} from '../@types/driver.model';
import { countries, states } from '@/shared/models/geo.model';
import {
  legStatus,
  race,
  maritStatus,
  gender,
  div,
  position,
  type,
  bankType
} from '../@types/sharedDriver.model';
import Loading from '@/shared/components/Loading.vue';
import {
  convertEmptyStringToNull,
  convertStringsToInteger
} from '@/utils/form.util';
import { DivisionModule } from '@/store';
import { formatCurrency } from '@/utils/currency';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { JobsService } from '@/shared/services/jobs/jobs.service';

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 15,
  allowLeadingZeroes: true
});

Component.registerHooks(['validations']);

@Component({
  components: {
    FileDownload,
    ToggleButton,
    Loading
  },
  mixins: [validationMixin]
})
export default class DriverModal extends Vue {
  @Prop() driverId: any;
  @Prop() disabled: boolean;
  @Prop() relatedOrders: any;

  addressError = false;
  url = null;
  masterType = 'driver';
  fileUploadService = FileUploadService;
  loading = false;
  convertDateFieldToAPIFormat = convertDateFieldToAPIFormat;
  saveClicked = false;
  importedPosition = null;
  errorModal = false;
  masks = {
    telNumMask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ') ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ],
    einMask: '##-#######',
    ssnMask: '###-##-####'
  };
  companyFilesMandKeys = [3, 4, 5, 7, 8, 9, 10, 11, 12, 13];
  otherFilesMandKeys = [3, 4, 5, 7, 8, 9];
  importedFiles = [];

  divOptions = div;
  posOptions = position;
  genderOptions = gender;
  stateOptions = states;
  countryOptions = countries;
  maritStatusOptions = maritStatus;
  raceOptions = race;
  legStatusOptions = legStatus;
  nationOptions = countries;
  typeOptions = type;
  truckStateOptions = states;
  bankTypeOptions = bankType;
  divisionModule = DivisionModule;
  currencyMask = currencyMask;

  driver = null;
  form = this.driver;

  driverValidation: any = null;

  constructor() {
    super();
  }

  get requiredFields(): Set<string> {
    const driverValidation =
      this.driver.position === 'Company'
        ? companyDriverValid
        : otherDriverValid;

    return new Set(
      Object.keys(driverValidation).filter(
        fieldName => driverValidation[fieldName].required
      )
    );
  }

  async changeDriverPosition() {
    this.driver = await DriverService.getDriverById(this.driverId);
    console.log('[BeforeChanges]: ', this.driver);
    this.importedPosition = this.driver.position;

    if (this.driver.position === 'Company') {
      for (const key in this.driver) {
        onlyOtherDriverData.forEach(item => {
          if (key === item) {
            delete this.driver[key];
          }
        });
      }

      onlyOtherFilesDriverData.forEach(item => {
        this.driver.files = this.driver.files.filter(
          file => file.category !== item
        );
      });

      const files = JSON.parse(JSON.stringify(compDriverData.files));
      files.forEach((file, index, arr) => {
        this.driver.files.forEach((driverFile, driverIndex, driverArr) => {
          if (file.category === driverFile.category) {
            arr[index] = { ...driverFile };
          }
        });
      });
      this.driver.files = JSON.parse(JSON.stringify(files));
    } else {
      for (const key in this.driver) {
        onlyCompDriverData.forEach(item => {
          if (key === item) {
            delete this.driver[key];
          }
        });
      }

      onlyCompFilesDriverData.forEach(item => {
        this.driver.files = this.driver.files.filter(
          file => file.category !== item
        );
      });

      const files = JSON.parse(JSON.stringify(otherDriverData.files));
      files.forEach((file, index, arr) => {
        this.driver.files.forEach((driverFile, driverIndex, driverArr) => {
          if (file.category === driverFile.category) {
            arr[index] = { ...driverFile };
          }
        });
      });
      this.driver.files = JSON.parse(JSON.stringify(files));
    }

    if (this.driverId && this.driver.photoUrl) {
      this.url = this.driver.photoUrl;
    }

    console.log('[AfterChanges]: ', this.driver);
    this.importedFiles = JSON.parse(JSON.stringify(this.driver.files));
    this.form = this.driver;
  }

  @Watch('driver.position')
  onPropertyChanged(value: string, oldValue: string) {
    console.log('position changed');
    if (this.driverId && this.importedPosition === value) {
      this.changeDriverPosition();
    } else {
      this.driver = this.getInitialDriverModel(value);
      this.url = null;
      this.driver.position = value;
      this.form = this.driver;
    }
  }

  created() {
    if (this.driverId) {
      this.changeDriverPosition();
    } else {
      this.driver = this.getInitialDriverModel('Company');
      this.form = this.driver;
    }
  }

  getInitialDriverModel(type) {
    const driver =
      type === 'Company' ? { ...compDriverData } : { ...otherDriverData };
    return JSON.parse(
      JSON.stringify({
        ...driver,
        division: this.divisionModule.defaultDivision
      })
    );
  }

  validations() {
    if (this.driver.position === 'Company') {
      return {
        form: companyDriverValid
      };
    } else {
      return {
        form: otherDriverValid
      };
    }
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  validateNotMandatory(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      if (!this.driver[val] || this.driver[val].trim() == '') {
        return null;
      }
      return $dirty ? !$error : null;
    }
  }

  changeActive() {
    if (this.relatedOrders && this.relatedOrders.length) {
      this.$bvModal.show('active-driver');
    } else {
      this.driver.active = !this.driver.active;
    }
  }

  async onFileUpload(file, index) {
    if (!file) {
      if (
        !this.driverId ||
        !this.importedFiles[index].filename ||
        !this.driver.files[index].filename
      ) {
        this.driver.files[index].documentName = null;
        this.driver.files[index].filename = null;
        this.driver.files[index].file = null;
        this.driver.files[index].tempS3FileName = '';
        this.driver.files[index].tempS3PreSignedUrl = '';
      } else {
        try {
          // await DriverService.deleteDriverFileById(
          //   this.driverId,
          //   this.driver.files[index].category
          // );

          this.driver.files[index].documentName = null;
          this.driver.files[index].filename = null;
          this.driver.files[index].file = null;
          this.driver.files[index].tempS3FileName = '';
          this.driver.files[index].tempS3PreSignedUrl = '';
        } catch (err) {
          ToastHelper.show('Driver file delete', err.message, 5000, 'danger');
        }
      }
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.driver.files[index].filename = file.filename || null;
        this.driver.files[index].documentName = file.documentName || null;
        this.driver.files[index].file = file.file || null;
        this.driver.files[index].tempS3FileName = file.tempS3FileName || null;
        this.driver.files[index].tempS3PreSignedUrl =
          file.tempS3PreSignedUrl || null;

        const base64img = reader.result;
        this.url = base64img;
      };
    }
  }

  closeDriver() {
    this.$bvModal.hide('driver-modal');
  }

  async uploadFilesToDB(data) {
    const fileUploads = [];

    data.files.forEach((file, index) => {
      if (file?.file) {
        fileUploads.push(
          this.fileUploadService.uploadFile(file.tempS3PreSignedUrl, file.file)
        );
      }
    });

    await Promise.all(fileUploads);
  }

  isFormInValid() {
    this.$v.form.$touch();

    if (this.$v.form.$anyError) {
      this.errorModal = true;
    }

    return this.$v.form.$anyError;
  }

  checkAddresses() {
    if (this.driver.address1 === this.driver.address2) {
      this.addressError = true;
      return false;
    }
  }

  async createDriver() {
    if (this.driver.cloudId === '') {
      this.driver.cloudId = null;
    }

    this.checkAddresses();

    if (this.addressError) {
      return;
    }

    this.saveClicked = true;

    const companyMandDates = [
      'hireDate',
      'driverLicenseExpirationDate',
      'medicalExpirationDate',
      'mvrDate',
      'roadTestDate',
      'drugTestDate'
    ];

    const otherMandDates = ['contractDate', 'insuranceExpirationDate'];

    const countBoolCompDates = () => {
      let coeff = false;
      companyMandDates.forEach(date => {
        if (!this.driver[date]) {
          coeff = true;
        }
      });
      return coeff;
    };

    const countBoolOtherDates = () => {
      let coeff = false;
      otherMandDates.forEach(date => {
        if (!this.driver[date]) {
          coeff = true;
        }
      });
      return coeff;
    };

    const countBoolCompFiles = () => {
      let coeff = false;
      this.companyFilesMandKeys.forEach(key => {
        if (!this.driver.files[key].filename) {
          coeff = true;
        }
      });
      return coeff;
    };

    const countBoolOtherFiles = () => {
      let coeff = false;
      this.otherFilesMandKeys.forEach(key => {
        console.log('[OtherFiles]: ', !this.driver.files[key].filename);
        if (!this.driver.files[key].filename) {
          coeff = true;
        }
      });
      return coeff;
    };

    const countBoolLegalEXP = () => {
      if (
        this.driver.legalStatus !== 'Citizenship' &&
        this.driver.legalStatus !== 'NA' &&
        !this.driver.legalExpirationDate
      ) {
        return true;
      }

      return false;
    };

    const countHireTerminateDateBool = () => {
      if (this.driver.terminateDate) {
        if (this.driver.hireDate > this.driver.terminateDate) {
          return true;
        }
      }

      return false;
    };

    const countHireRehireDateBool = () => {
      if (this.driver.rehireDate) {
        if (this.driver.hireDate >= this.driver.rehireDate) {
          return true;
        }
      }

      return false;
    };

    const countContractTerminateDateBool = () => {
      if (this.driver.terminateDate) {
        const contract = convertDateFieldToAPIFormat(this.driver.contractDate);
        const terminate = convertDateFieldToAPIFormat(
          this.driver.terminateDate
        );

        if (contract > terminate) {
          return true;
        }
      }

      return false;
    };

    if (
      this.driver.position === 'Company' &&
      (this.isFormInValid() ||
        countBoolCompFiles() ||
        countBoolCompDates() ||
        countHireRehireDateBool() ||
        countHireTerminateDateBool() ||
        countBoolLegalEXP())
    ) {
      return;
    } else if (
      this.driver.position !== 'Company' &&
      (this.isFormInValid() ||
        countBoolOtherFiles() ||
        countBoolOtherDates() ||
        countContractTerminateDateBool())
    ) {
      return;
    }

    const toSendData = { ...this.driver };
    toSendData.email = toSendData.email
      ? toSendData.email.replaceAll(' ', '')
      : toSendData.email;

    toSendData.rate = formatCurrency(toSendData.rate);

    [
      'cellPhone',
      'homePhone',
      'emergencyContact',
      'emergencyPhone',
      'ein',
      'ssn'
    ].forEach(key => {
      if (toSendData[key]) {
        toSendData[key] = toSendData[key].replace(/\D/g, '');
      }
    });

    convertEmptyStringToNull(toSendData);

    const itemsToDeleteIfUpdate = [
      'createdBy',
      'createdDate',
      'updatedBy',
      'updatedDate',
      'id'
    ];

    const itemsToDeleteFromFilesIfUpdate = [
      'id',
      'createdBy',
      'createdDate',
      'updatedBy',
      'updatedDate'
    ];

    if (this.driverId) {
      try {
        itemsToDeleteIfUpdate.forEach(item => {
          delete toSendData[item];
        });

        itemsToDeleteFromFilesIfUpdate.forEach(item => {
          toSendData.files.forEach(file => {
            delete file[item];
          });
        });

        this.loading = true;
        await this.uploadFilesToDB(toSendData);
        toSendData.files.forEach((file, index, arr) => {
          delete arr[index].file;
          if (!file.tempS3FileName) {
            delete file.tempS3FileName;
            delete file.tempS3PreSignedUrl;
          }
        });

        delete toSendData.photoUrl;

        await DriverService.saveDriver(this.driverId, toSendData);
        this.loading = false;

        ToastHelper.show('Driver update', 'Update successful', 5000, 'success');
      } catch (err) {
        ToastHelper.show(
          'Driver create',
          err.response?.data?.error || err.message,
          5000,
          'danger'
        );
      }
    } else {
      try {
        this.loading = true;
        await this.uploadFilesToDB(toSendData);

        toSendData.files = toSendData.files.filter(file => !!file.filename);
        toSendData.files.forEach((file, index, arr) => {
          delete toSendData.files[index].file;
        });

        await DriverService.createDriver(toSendData);
        this.loading = false;

        ToastHelper.show('Driver create', 'Create successful', 5000, 'success');
      } catch (err) {
        ToastHelper.show(
          'Driver create',
          err.response?.data?.error || err.message,
          5000,
          'danger'
        );
      }
    }
    console.log('[Finished]: ', toSendData);

    this.$bvModal.hide('driver-modal');
  }
}
